.app-container {
  // margin-left: 3px !important;
  //position: relative;
  margin-top: 70px !important;
  //margin-right: 35px !important;
  padding: 15px 30px 30px !important;
}

.content-wrapper {
  background-color: #FAFBFC !important;
}

.impersonation-bar {
  position: sticky;
  margin-left: 0;
  margin-right: 0;
  margin-top: 70px !important;
  margin-bottom: -70px !important;
  height: 40px;
  width: 100%;
  background: #FA6557;
  text-align: center;
  top: 70px;
  z-index: 9;

  label {
    color: #27395A;
    height: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  a {
    :hover {
      color: #27395A !important;
      background: rgba(255, 255, 255, 0.3);
    }

    color: #27395A;
    background: rgba(255, 255, 255, 0.1);
    padding: 5px 5px 5px 5px;
    border-radius: 5px;
    margin-left: 5px;
  }

}