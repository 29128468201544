.cont-pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
}

.css-189vk5p.Mui-selected {
    background-color: #35CC62 !important;
    border: 2px solid #35CC62 !important;
    color: #FFFFFF !important
}

.css-189vk5p {
    color: #B0B9C9 !important;
    border: 2px solid #E6EAF1 !important
}

@media (max-width: 600px) {
    .cont-pagination {
        justify-content: center;
    }
}