.input-box {
  position: relative;

  .icon {
    position: absolute;
    top: 35px;
    left: 400px;
    font-size: 20px;
  }

  .search-icon {
    background: white url('/images/search.svg') right no-repeat;
    padding-right: 17px;
    left: 10px;
    width: 30px;
    background-origin: content-box;
  }

  .calendar-icon {
    background: white url('/images/calendar-grey.svg') right no-repeat;
    padding-right: 17px;
    left: 10px;
    width: 30px;
    background-origin: content-box;
  }

  .label {
    height: 18px;
    color: #333333;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 18px;
    margin-bottom: 5px;
  }
;

  .box {
    box-sizing: border-box;
    height: 48px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #FFFFFF;
    margin-top: 0px;
    text-indent: 10px;
    margin-bottom: 20px !important;
  }

  .field {
    box-sizing: border-box;
    height: 93px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #FFFFFF;
    margin-top: 0px;
    text-indent: 10px;
    margin-bottom: 20px !important;
  }

  .error {
    border: 1px solid #FF584E;
  }

  .error-message {
    width: 100%;
    color: #FF584E;
    font-size: 12px;
    letter-spacing: 0.07px;
    margin-left: 2px;
    margin-top: -10px !important;
    margin-bottom: 1rem !important;
  }

  .phoneInputField {
    box-sizing: border-box;
    height: 48px !important;
    margin-bottom: 20px !important;
    width: 100% !important;;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;;
    border-radius: 4px !important;;
    background-color: #FFFFFF !important;;
    //margin-top: 5px !important;;
    text-indent: 10px !important;;
  }
}

.react-tel-input {
  margin-bottom: 20px !important;
}
