.header {
  position: absolute;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  height: 82px;
  width: 100%;
  background-color: #FFFFFF !important;

  .profile {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
}


svg {
  height: 30px;
  width: 36px;
}

path {
  stroke: #ECECEC;
  fill: #ECECEC;
}

.initials {
  fill: #9C9C9C;
  font: bold 30px Muli;
}

.main-nav {
  box-shadow: 5px 5px 25px 0 rgba(179, 179, 179, 0.25) !important;
  z-index: 99;
}

.title {
  // height: 43px;
  //font-family: "Gilroy-Bold";
  padding: 10px;
  color: #35CC62;
  font-size: 22px;
  font-weight: 900;
  line-height: 27px;
}

.side-navigation-toggle {
  display: none;
}

@media only screen and (max-width: 768px) {
  .side-navigation-toggle {
    display: block;
  }
  #sidebar-overlay {
    z-index: 0;
  }
}
