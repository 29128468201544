.image {
    width: 120px;
    height: 120px;
    margin-left: -15px;
}

.container {
    -webkit-clip-path: url(#svgPath);
    clip-path: url(#svgPath);
    background-color: #E1E3E6;
}